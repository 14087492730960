import PropTypes from 'prop-types'
import * as Sentry from "@sentry/react";

const integrations = [
	new Sentry.BrowserTracing(),
	// typeof window !== 'undefined' ? new Sentry.Replay : false,
].filter(f => !!f);

Sentry.init({
	dsn: process.config.sentry?.dsn,
	integrations: integrations,
	tracesSampleRate: process.config.sentry?.tracesSampleRate ?? 1.0,
	replaysSessionSampleRate: process.config.sentry?.replaysSessionSampleRate ?? 0.1,
	replaysOnErrorSampleRate: process.config.sentry?.replaysOnErrorSampleRate ?? 1.0,
	beforeSend(event, hint) {
		const error = hint.originalException;
		if (error?.message) {
			if(
				error.message.match(/Non-Error promise rejection captured with keys: error, success, validation_error/)
			) {
				return null;
			}
			if (
				error.message.match(/loading/i) &&
				error.message.match(/chunk/i) &&
				error.message.match(/failed/i)
			) {
				event.fingerprint = ["Chunk error"];
			}
		}
		return event;
	},
});

export const SentryProvider = ({ children }) => {	
	return children;
}

SentryProvider.propTypes = {
	children: PropTypes.node,
}

export default SentryProvider;
